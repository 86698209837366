<template>
    <div class="m-3">
        <ListAccordionVue class="mb-3"></ListAccordionVue>
        <ListValueAccordionVue class="mb-3"></ListValueAccordionVue>
        <FieldTypeAccordionVue class="mb-3"></FieldTypeAccordionVue>
        <FieldDefinitionAccordionVue class="mb-3"></FieldDefinitionAccordionVue>
    </div>
</template>

<script>
import FieldDefinitionAccordionVue from '../components/forms/FieldDefinitionAccordion.vue'
import FieldTypeAccordionVue from '../components/forms/FieldTypeAccordion.vue'
import ListAccordionVue from '../components/forms/ListAccordion.vue'
import ListValueAccordionVue from '../components/forms/ListValueAccordion.vue'

export default {
    components: {
        ListAccordionVue,
        ListValueAccordionVue,
        FieldTypeAccordionVue,
        FieldDefinitionAccordionVue,
    },
}
</script>

<style></style>
