<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Définition des champs</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
                <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
            </template>
            <template #accordionContent>
                <div>
                    <vue-good-table
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="fieldDefinitions"
                        max-height="400px"
                        :fixed-header="true"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                            <div v-else-if="props.column.field === 'field_type.type'">
                                {{ props.row.field_type.type }}
                                {{
                                    props.row.field_type.list_of_value ? props.row.field_type.list_of_value.domain : ''
                                }}
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewFieldDefinition" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleModal }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="fieldType">Type de champs</label>
                        <select id="fieldType" class="uk-select" v-model="fieldType">
                            <option :value="undefined" v-if="!fieldTypes[0]">Pas de list</option>
                            <option v-for="(it, idx) in fieldTypes" :key="idx" :value="it">
                                {{ it.type }} {{ it.list_of_value ? it.list_of_value.domain : '' }}
                            </option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="g2_code">G2 Code</label>
                        <input id="g2_code" class="uk-input" type="text" v-model="form.g2_code" required />
                    </div>
                    <div class="uk-margin">
                        <label for="wording">Libellé</label>
                        <input id="wording" class="uk-input" type="text" v-model="form.wording" required />
                    </div>
                    <div class="uk-margin" v-if="fieldType ? fieldType.type === 'matricule_compteur' : ''">
                        <label for="scannable" class="c-mr">Scannable code barre</label>
                        <input id="scannable" class="uk-checkbox" type="checkbox" v-model="form.scannable" />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer cette définition ? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            loading: false,
            titleModal: '',
            form: {
                g2_code: '',
                wording: '',
                scannable: 0,
            },
            columns: [
                {
                    label: 'Type de champ',
                    field: 'field_type.type',
                },
                {
                    label: 'G2 code',
                    field: 'g2_code',
                },
                {
                    label: 'Libellé',
                    field: 'wording',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            fieldType: undefined,
            selected: [],
            isUpdate: false,
        }
    },
    methods: {
        clearInput() {
            this.form = {
                g2_code: '',
                wording: '',
                scannable: 0,
            }
        },
        onPLusClicked() {
            this.titleModal = 'Nouveau définition'
            this.isUpdate = false
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldDefinition.$el).show()
        },
        onSave() {
            if (!this.form.g2_code) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir tous les champs')
                return
            }
            if (!this.fieldType) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez créer d`abord un type de champ')
                return
            }
            const data = {
                g2_code: this.form.g2_code,
                wording: this.form.wording,
                scannable: this.form.scannable,
                field_type_id: this.fieldType.id,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateFieldDefinition(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldDefinition.$el).hide()
                    this.$store.dispatch('adminForm/setFieldDefinitions', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addFieldDefinition(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldDefinition.$el).hide()
                    this.$store.dispatch('adminForm/setFieldDefinitions', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.titleModal = 'Modification définition'
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldDefinition.$el).show()
        },
        async getAllFieldDefinition() {
            this.loading = true
            this.$http
                .getAllFieldDefinition()
                .then((res) => {
                    this.$store.dispatch('adminForm/setFieldDefinitions', res)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateAll() {
            this.getAllFieldDefinition()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteFieldDefinition(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillInput(data) {
            this.form = {
                g2_code: data.g2_code,
                wording: data.wording,
                scannable: data.scannable,
            }
            if (data.field_type.type === 'dropdown') {
                this.fieldType = this.fieldTypes.find((f) => f.id === data.field_type.id)
            } else {
                this.fieldType = data.field_type
            }
        },
    },
    computed: {
        ...mapState('adminForm', { fieldDefinitions: 'fieldDefinitions' }),
        ...mapState('adminForm', { fieldTypes: 'fieldTypes' }),
    },
    mounted() {
        this.getAllFieldDefinition()
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
