<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Liste des domaines</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
                <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
            </template>
            <template #accordionContent>
                <div>
                    <vue-good-table
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="$store.state.adminForm.list"
                        max-height="400px"
                        :fixed-header="true"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewList" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleMoadal }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="domain">Domaine</label>
                        <input id="domain" class="uk-input" type="text" v-model="form.domain" required />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer ce domaine ? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            titleMoadal: '',
            form: {
                domain: '',
            },
            columns: [
                {
                    label: 'Domaine',
                    field: 'domain',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            selected: [],
            isUpdate: false,
        }
    },
    methods: {
        clearInput() {
            this.form = {
                domain: '',
            }
        },
        onPLusClicked() {
            this.titleMoadal = 'nouveau domaine'
            this.isUpdate = false
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewList.$el).show()
        },
        onSave() {
            if (!this.form.domain) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir tous les champs')
                return
            }
            const data = {
                domain: this.form.domain,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateList(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewList.$el).hide()
                    this.$store.dispatch('adminForm/setList', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addList(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewList.$el).hide()
                    this.$store.dispatch('adminForm/setList', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.selected[0] = props
            this.titleMoadal = 'modification domaine'
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewList.$el).show()
        },
        async getAllList() {
            this.loading = true
            this.$http
                .getAllList()
                .then((res) => {
                    this.$store.dispatch('adminForm/setList', res)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateAll() {
            this.getAllList()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteList(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillInput(data) {
            this.form = {
                domain: data.domain,
            }
        },
    },
    mounted() {
        this.getAllList()
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
