<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Liste des valeurs</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
                <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
            </template>
            <template #accordionContent>
                <div>
                    <vue-good-table
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="listValues"
                        max-height="400px"
                        :fixed-header="true"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewListValue" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleModal }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="list">Valeur</label>
                        <select id="list" class="uk-select" v-model="mList">
                            <option :value="undefined" v-if="!listFormated[0]">Pas de list</option>
                            <option v-for="(it, idx) in listFormated" :key="idx" :value="it">{{ it.domain }}</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="g2_value">G2 Value</label>
                        <input id="g2_value" class="uk-input" type="number" v-model="form.g2_value" required />
                    </div>
                    <div class="uk-margin">
                        <label for="wording">Libellé</label>
                        <input id="wording" class="uk-input" type="text" v-model="form.wording" required />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer cette valeur ? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            loading: false,
            titleModal: '',
            form: {
                g2_value: '',
                wording: '',
            },
            columns: [
                {
                    label: 'Liste',
                    field: 'list_of_value.domain',
                },
                {
                    label: 'G2 Value',
                    field: 'g2_value',
                },
                {
                    label: 'Libellé',
                    field: 'wording',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            mList: undefined,
            selected: [],
            isUpdate: false,
        }
    },
    methods: {
        clearInput() {
            this.form = {
                g2_value: '',
                wording: '',
            }
        },
        onPLusClicked() {
            this.titleModal = 'Nouvelle valeur'
            this.isUpdate = false
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewListValue.$el).show()
        },
        onSave() {
            if (!this.form.g2_value || !this.form.wording) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir tous les champs')
                return
            }
            if (!this.mList) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez créer d`abord une liste')
                return
            }
            const data = {
                g2_value: this.form.g2_value,
                wording: this.form.wording,
                list_of_value_id: this.mList.id,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateListValue(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewListValue.$el).hide()
                    this.$store.dispatch('adminForm/setListValues', res)
                })
                .finally((this.loading = false))
        },
        add(data) {
            this.loading = true
            this.$http
                .addListValue(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewListValue.$el).hide()
                    this.$store.dispatch('adminForm/setListValues', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.titleModal = 'Modification valeur'
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewListValue.$el).show()
        },
        async getAllListValue() {
            this.loading = true
            this.$http
                .getAllListValue()
                .then((res) => {
                    this.$store.dispatch('adminForm/setListValues', res)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateAll() {
            this.getAllListValue()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteListValue(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async getAllList() {
            const rows = await this.$http.getAllList()
            this.$store.dispatch('adminForm/setList', rows)
        },
        fillInput(data) {
            this.form = {
                wording: data.wording,
                g2_value: data.g2_value,
            }
            this.mList = data.list_of_value
        },
    },
    computed: {
        ...mapState('adminForm', { listValues: 'listValues' }),
        ...mapState('adminForm', { list: 'list' }),
        listFormated() {
            return this.list.map((l) => {
                return {
                    id: l.id,
                    domain: l.domain,
                }
            })
        },
    },
    watch: {
        list(val) {
            if (!this.mList && val[0]) this.mList = val[0]
        },
    },
    mounted() {
        this.getAllList()
        this.getAllListValue()
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
