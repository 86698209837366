<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Type des champs</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
                <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
            </template>
            <template #accordionContent>
                <div>
                    <vue-good-table
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="fieldTypes"
                        max-height="400px"
                        :fixed-header="true"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewFieldType" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleModal }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="fieldType">{{ labelTypeChamp }}</label>
                        <input id="fieldType" class="uk-input" type="text" v-model="fieldType.value" required />
                    </div>
                    <div class="uk-margin" v-if="isDropDown">
                        <label for="list">Domaine</label>
                        <select id="list" class="uk-select" v-model="mList">
                            <option :value="undefined" v-if="!list[0]">Pas de list</option>
                            <option v-for="(it, idx) in list" :key="idx" :value="it">{{ it.domain }}</option>
                        </select>
                    </div>
                    <div class="uk-margin" v-else>
                        <label for="regex_validate_field">Validation regex</label>
                        <input
                            id="regex_validate_field"
                            class="uk-input"
                            type="text"
                            v-model="form.regex_validate_field"
                            required
                        />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer ce type de champs ? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            DROPDOWN: 'dropdown',
            titleModal: '',
            loading: false,
            labelTypeChamp: '',
            form: {
                regex_validate_field: '',
            },
            columns: [
                {
                    label: 'Liste',
                    field: 'list_of_value.domain',
                },
                {
                    label: 'Type',
                    field: 'type',
                },
                {
                    label: 'Validation regex',
                    field: 'regex_validate_field',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            mList: undefined,
            selected: [],
            isUpdate: false,
            fieldType: {
                value: '',
                label: 'Texte',
            },
            types: [
                {
                    value: 'text',
                    label: 'Texte',
                },
                {
                    value: 'integer',
                    label: 'Numérique',
                },
                {
                    value: 'year',
                    label: 'Année',
                },
                {
                    value: 'datetime_iso',
                    label: 'Date',
                },
                {
                    value: 'dropdown',
                    label: 'Liste des valeurs',
                },
                {
                    value: 'matricule',
                    label: 'Matricule',
                },
                {
                    value: 'decimal_2',
                    label: 'Nombre Décimale',
                },
                {
                    value: 'matricule_compteur',
                    label: 'Matricule compteur',
                },
            ],
        }
    },
    methods: {
        clearInput() {
            this.form = {
                regex_validate_field: '',
            }
        },
        onPLusClicked() {
            this.titleModal = 'Nouveau type de champ'
            this.labelTypeChamp = 'Type de champs'
            this.isUpdate = false
            this.clearInput()
            this.fieldType.value = ''
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldType.$el).show()
        },
        onSave() {
            if (!this.fieldType.value) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez entrer le type du champ')
                return
            }
            if (this.fieldType.value == this.DROPDOWN && !this.mList) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez créer d`abord une liste')
                return
            }
            const data = {
                type: this.fieldType.value,
            }
            if (this.fieldType.value == this.DROPDOWN) {
                data.list_of_value = this.mList.id
            } else {
                data.regex_validate_field = this.form.regex_validate_field
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateFieldType(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldType.$el).hide()
                    this.$store.dispatch('adminForm/setFieldTypes', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addFieldType(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldType.$el).hide()
                    this.$store.dispatch('adminForm/setFieldTypes', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.titleModal = 'Modification type de champs'
            this.labelTypeChamp = 'champs'
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFieldType.$el).show()
        },
        async getAllFieldType() {
            this.loading = true
            this.$http
                .getAllFieldType()
                .then((res) => {
                    this.$store.dispatch('adminForm/setFieldTypes', res)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateAll() {
            this.getAllFieldType()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteFieldType(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillInput(data) {
            this.form = {
                regex_validate_field: data.regex_validate_field,
            }
            if (data.list_of_value) {
                this.mList = this.list.find((l) => l.domain === data.list_of_value.domain)
            } else {
                this.mList = undefined
            }
            this.fieldType.value = data.type
        },
    },
    computed: {
        ...mapState('adminForm', { fieldTypes: 'fieldTypes' }),
        ...mapState('adminForm', { list: 'list' }),
        isDropDown() {
            return this.fieldType && this.fieldType.value === this.DROPDOWN
        },
    },
    watch: {
        list(val) {
            if (val && val[0]) this.mList = val[0]
        },
    },
    mounted() {
        this.getAllFieldType()
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
